import styled from 'styled-components'

export const StyleSectionHeaderService = styled.div`
  padding-top: 60px;
  padding-bottom: 50px;
  @media only screen and (min-width: 768px) {
    padding-top: 70px;
  }
  .head-content__title {
    color: #fff;
    font-size: 48px;
    line-height: 77px;
    @media only screen and (min-width: 768px) {
      font-size: 88px;
      line-height: 140px;
    }
    @media only screen and (min-width: 1024px) {
      font-size: 64px;
      line-height: 110px;
    }
    @media only screen and (min-width: 1536px) {
      font-size: 84px;
      line-height: 140px;
    }
  }
  p {
    @media only screen and (min-width: 768px) {
      font-size: 18px;
      line-height: normal;
    }
  }
`
