import React from 'react'
import { Typography } from '@/components/elements'
import { StyleListBullet } from './style'
import classNames from 'classnames'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

type ListBulletProps = {
  list: string[]
  listStyle?: 'column' | 'row'
  className?: string
}

gsap.registerPlugin(ScrollTrigger)

const ListBullet = (props: ListBulletProps) => {
  const { list, className, listStyle = 'row' } = props
  const megaWrapper = classNames(
    'list',
    {
      // eslint-disable-next-line prettier/prettier
      'grid': listStyle === 'column',
      'list-row': listStyle === 'row',
    },
    className,
  )
  return (
    <StyleListBullet className={megaWrapper}>
      {list.map((item) => (
        <Typography tag='li' key={item} className='list-bullet'>
          {item}
        </Typography>
      ))}
    </StyleListBullet>
  )
}

export default ListBullet
