import React from 'react'
import { StyleSectionHeaderService } from './style'
import classNames from 'classnames'
import { Button, ImgResponsive } from '@/components/elements'
import { TitleShadow } from '@/components/modules'

type SectionHeaderServiceProps = {
  title?: string | React.ReactNode
  bgImage?: string
  bgImageMd?: string
  bgImageSm?: string
  bgColors?: string
  style?: React.CSSProperties
  className?: string
  bgImageWidth?: number
  bgImageHeight?: number
  description?: string
  btnText?: string
  onClick?: () => void
  prologue?: string | React.ReactNode
  titleShadow?: string | React.ReactNode
  subtitle?: string | React.ReactNode
  colorPrologue?: string
  colorTitleShadow?: string
  colorSubtitle?: string
  align?: 'left' | 'center' | 'right'
  content?: string | React.ReactNode
  subTitlePosition?: 'top' | 'bottom'
}

const SectionHeaderService = (props: SectionHeaderServiceProps) => {
  const {
    title = 'Article',
    description = `"Empowering your business with the Excellence website"`,
    btnText,
    bgImage = 'https://twinsynergy.co.th/wp-content/uploads/2022/03/twin-cover-2022-blue.jpg',
    bgImageMd,
    bgImageSm,
    bgColors = '#FFFFFF',
    style,
    className = '',
    bgImageWidth,
    bgImageHeight,
    titleShadow,
    colorTitleShadow,
    prologue,
    colorPrologue,
    align,
    content,
    onClick,
    subtitle,
    colorSubtitle,
    subTitlePosition = 'bottom',
  } = props

  const mergeStyle = classNames('head-content', className)

  return (
    <StyleSectionHeaderService
      className={mergeStyle}
      style={{ backgroundColor: bgColors, ...style }}
    >
      <div className='container mx-auto grid grid-cols-1 items-center lg:grid-cols-2'>
        <ImgResponsive
          src={bgImage}
          alt='Twin Synergy'
          sourceImgMd={bgImageMd}
          sourceImgSm={bgImageSm}
          width={bgImageWidth}
          height={bgImageHeight}
        />
        <div className='space-y-8 px-4'>
          <h1 className='head-content__title'>{title}</h1>
          <p>{description}</p>
          {btnText && (
            <Button htmlType='button' className='btn-neutral' onClick={onClick}>
              {btnText}
            </Button>
          )}
        </div>
      </div>
      <div className='pb-36 pt-44'>
        <div className='container mx-auto space-y-8 px-4' data-aos-delay='10'>
          <TitleShadow
            prologue={prologue}
            colorPrologue={colorPrologue}
            title={titleShadow}
            colorTitle={colorTitleShadow}
            align={align}
            subtitle={subtitle}
            colorSubtitle={colorSubtitle}
            subTitlePosition={subTitlePosition}
          />
          {content}
        </div>
      </div>
    </StyleSectionHeaderService>
  )
}

export default SectionHeaderService
